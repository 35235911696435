<cybexer-widget-header title="{{ 'ui.settings.settings' | translate }}" [inline]="true">
  <div class="spacer"></div>
  <span class="date"
    >{{ 'ui.settings.lastModified' | translate }} {{ lastModified | isaDate: 'short' }}</span
  >
</cybexer-widget-header>
<cybexer-widget-body>
  @if (settingsForm) {
    <form
      role="form"
      [formGroup]="settingsForm"
      (ngSubmit)="save()"
      class="isa-form global-settings"
    >
      <mat-card>
        <mat-card-title>{{ 'ui.settings.modules' | translate }}</mat-card-title>
        <mat-card-content>
          <mat-slide-toggle
            class="switch-container"
            formControlName="gamenetEnabled"
            labelPosition="after"
          >
            {{ 'ui.settings.showCyberRange' | translate }}
          </mat-slide-toggle>
        </mat-card-content>
      </mat-card>
      @if (settingsForm.controls.gamenetEnabled.value) {
        <mat-card>
          <mat-card-title>{{ 'ui.settings.observerMode' | translate }}</mat-card-title>
          <mat-card-content>
            <mat-slide-toggle
              class="switch-container"
              formControlName="observerEnabled"
              labelPosition="after"
            >
              {{ 'ui.settings.enable' | translate }}
            </mat-slide-toggle>
            @if (settingsForm.controls.observerEnabled.value) {
              <div class="observer-configuration-container" formGroupName="observerSettings">
                <mat-form-field class="form-field-full">
                  <mat-label>{{ 'ui.settings.observerKey' | translate }}</mat-label>
                  <input autocomplete="off" type="text" matInput formControlName="key" />
                  <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    aria-label="Generate"
                    (click)="resetObserverKey($event)"
                    tabindex="-1"
                  >
                    <i class="fas fa-redo" aria-hidden="true"></i>
                  </button>
                </mat-form-field>
                <mat-form-field class="form-field-full">
                  <mat-label>{{ 'ui.settings.primaryExercise' | translate }}</mat-label>
                  <mat-select class="observer-exercise-id-field" formControlName="exerciseId">
                    <mat-option [value]="null"></mat-option>
                    @for (exerciseOverview of exerciseOverviews; track exerciseOverview) {
                      <mat-option [value]="exerciseOverview.id">
                        {{ exerciseOverview.name }}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                @if (isDefaultExerciseSelected()) {
                  <mat-selection-list formControlName="enabledViews">
                    @for (view of observableViews; track view) {
                      <mat-list-option checkboxPosition="before" [value]="view.type">
                        <span matLine>{{ view.name }}</span>
                        <span matLine>
                          <span class="disable-uppercase tone-down">
                            {{ urlProtocolHost + view.path }}
                          </span>
                        </span>
                        <i
                          matListItemIcon
                          matRipple
                          class="copy-observable-view fas fa-copy"
                          (click)="$event.stopPropagation(); copyViewUrl(view)"
                          [matTooltip]="'ui.settings.copyToClipboard' | translate"
                        ></i>
                      </mat-list-option>
                    }
                  </mat-selection-list>
                }
              </div>
            }
          </mat-card-content>
        </mat-card>
      }
      <mat-card>
        <mat-card-title>{{ 'ui.settings.ldapConfiguration' | translate }}</mat-card-title>
        <mat-card-content>
          <mat-slide-toggle class="switch-container" formControlName="ldapEnabled">
            {{ 'ui.settings.enable' | translate }}
          </mat-slide-toggle>
          @if (settingsForm.controls.ldapEnabled.value) {
            <div class="ldap-configuration-container" formGroupName="ldapSettings">
              <mat-form-field class="form-field-full">
                <mat-label>{{ 'ui.settings.principal' | translate }}</mat-label>
                <input
                  required
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="principal"
                />
              </mat-form-field>
              <mat-form-field class="form-field-full">
                <mat-label>{{ 'ui.settings.principalSuffix' | translate }}</mat-label>
                <input
                  required
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="principalSuffix"
                />
              </mat-form-field>
              <mat-form-field class="form-field-full">
                <mat-label>{{ 'ui.settings.searchBase' | translate }}</mat-label>
                <input
                  required
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="searchBase"
                />
              </mat-form-field>
              <mat-form-field class="form-field-full">
                <mat-label
                  >{{ !this.ldapPasswordRequired ? ('ui.settings.new' | translate) + ' ' : ''
                  }}{{ 'ui.settings.password' | translate }}</mat-label
                >
                <input
                  [required]="ldapPasswordRequired"
                  autocomplete="off"
                  type="password"
                  matInput
                  formControlName="password"
                />
              </mat-form-field>
              <div class="ldap-url-container">
                <div>
                  <span>{{ 'ui.settings.urls' | translate }}</span>
                  <button type="button" mat-icon-button (click)="addLdapUrl()">
                    <i class="fas fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="ldap-url-list" formArrayName="urls">
                  @for (url of ldapUrls.controls; track url; let i = $index) {
                    <mat-form-field class="ldap-url form-field-full">
                      <mat-label>{{ 'ui.settings.url' | translate }} {{ i + 1 }}</mat-label>
                      <input
                        required
                        autocomplete="off"
                        type="text"
                        matInput
                        formControlName="{{ i }}"
                      />
                      <button type="button" mat-icon-button matSuffix (click)="removeLdapUrl(i)">
                        <i class="fas fa-times" aria-hidden="true"></i>
                      </button>
                    </mat-form-field>
                  }
                </div>
              </div>
              <div class="ldap-group-mappings">
                <div>
                  <span>{{ 'ui.settings.groupMappings' | translate }}</span>
                  <button type="button" mat-icon-button (click)="addLdapGroupMapping()">
                    <i class="fas fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="ldap-group-mapping-list" formArrayName="groupMappings">
                  @for (url of ldapGroupMappings.controls; track url; let i = $index) {
                    <div class="ldap-group-mapping" formGroupName="{{ i }}">
                      <mat-form-field class="ldap-group-mapping-field">
                        <mat-label>{{ 'ui.settings.role' | translate }}</mat-label>
                        <input
                          required
                          autocomplete="off"
                          type="text"
                          matInput
                          formControlName="role"
                        />
                      </mat-form-field>
                      <mat-form-field class="ldap-group-mapping-field">
                        <mat-label>{{ 'ui.settings.distinguishedName' | translate }}</mat-label>
                        <input
                          required
                          autocomplete="off"
                          type="text"
                          matInput
                          formControlName="distinguishedName"
                        />
                        <button
                          type="button"
                          mat-icon-button
                          matSuffix
                          (click)="removeLdapGroupMapping(i)"
                        >
                          <i class="fas fa-times" aria-hidden="true"></i>
                        </button>
                      </mat-form-field>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </mat-card-content>
      </mat-card>
      @if (settingsForm.get('gamenetEnabled').value) {
        <mat-card>
          <mat-card-title>{{ 'ui.settings.cyberRange' | translate }}</mat-card-title>
          <mat-card-content formGroupName="gamenetSettings" class="gamenet-settings">
            <mat-form-field
              class="exercise-interval-field interval-field"
              hintLabel="{{ 'ui.settings.milliseconds' | translate }}"
            >
              <mat-label>{{ 'ui.settings.moduleRefreshInterval' | translate }}</mat-label>
              <input
                id="exerciseRefreshInterval"
                type="number"
                min="10000"
                matInput
                formControlName="exerciseRefreshInterval"
                required
              />
            </mat-form-field>
            <mat-form-field
              class="interval-field"
              hintLabel="{{ 'ui.settings.milliseconds' | translate }}"
            >
              <mat-label>{{ 'ui.settings.scoringRefreshInterval' | translate }}</mat-label>
              <input
                id="scoringTimelineWidgetRefreshInterval"
                type="number"
                min="10000"
                matInput
                formControlName="scoringTimelineWidgetRefreshInterval"
                required
              />
            </mat-form-field>
            <mat-slide-toggle
              class="switch-container"
              formControlName="soundOnTeamChangeEnabled"
              labelPosition="after"
            >
              {{ 'ui.settings.teamScoring' | translate }}
            </mat-slide-toggle>
            @if (settingsForm.get('gamenetSettings.soundOnTeamChangeEnabled').value) {
              <isa-sound-select
                [selectedSoundId]="settingsForm.get('gamenetSettings.selectedSoundId').value"
                (onSelect)="onSoundSelect($event, 'gamenetSettings.selectedSoundId')"
              ></isa-sound-select>
            }
            <mat-divider></mat-divider>
            <mat-slide-toggle
              class="switch-container"
              formControlName="ctfPodiumFirstPlaceSoundEnabled"
              labelPosition="after"
            >
              {{ 'ui.settings.ctfFirstPlaceSound' | translate }} <i class="fas fa-trophy gold"></i>
            </mat-slide-toggle>
            @if (settingsForm.get('gamenetSettings.ctfPodiumFirstPlaceSoundEnabled').value) {
              <isa-sound-select
                [selectedSoundId]="
                  settingsForm.get('gamenetSettings.ctfPodiumFirstPlaceSoundId').value
                "
                [defaultSoundPath]="DefaultSound.BASS_BEEP"
                (onSelect)="onSoundSelect($event, 'gamenetSettings.ctfPodiumFirstPlaceSoundId')"
              ></isa-sound-select>
            }
            <mat-divider></mat-divider>
            <mat-slide-toggle
              class="switch-container"
              formControlName="ctfPodiumSecondThirdPlaceSoundEnabled"
              labelPosition="after"
            >
              {{ 'ui.settings.ctfSecondThirdPlaceSound' | translate }}
              <i class="fas fa-trophy silver"></i><i class="fas fa-trophy bronze"></i>
            </mat-slide-toggle>
            @if (settingsForm.get('gamenetSettings.ctfPodiumSecondThirdPlaceSoundEnabled').value) {
              <isa-sound-select
                [selectedSoundId]="
                  settingsForm.get('gamenetSettings.ctfPodiumSecondThirdPlaceSoundId').value
                "
                (onSelect)="
                  onSoundSelect($event, 'gamenetSettings.ctfPodiumSecondThirdPlaceSoundId')
                "
              ></isa-sound-select>
            }
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="recalculateAvailabilityData()"
              [disabled]="recalculatingAvailabilityData == null || recalculatingAvailabilityData"
            >
              @if (recalculatingAvailabilityData) {
                <div class="spinner-wrapper">
                  <mat-progress-spinner
                    class="recalculating-spinner"
                    mode="indeterminate"
                    [color]="'accent'"
                    [diameter]="20"
                  ></mat-progress-spinner>
                  <span>{{ 'ui.settings.recalculating' | translate }}</span>
                </div>
              }
              @if (!recalculatingAvailabilityData) {
                {{ 'ui.settings.recalculateData' | translate }}
              }
            </button>
          </mat-card-content>
        </mat-card>
      }
      @if (settingsForm.get('gamenetEnabled').value) {
        <mat-card>
          <mat-card-title>{{ 'ui.settings.vlmConfiguration' | translate }}</mat-card-title>
          <mat-card-content class="vlm-settings">
            <mat-slide-toggle class="switch-container" formControlName="vlmEnabled"
              >{{ 'ui.settings.enable' | translate }}
            </mat-slide-toggle>
            @if (settingsForm.controls.vlmEnabled.value) {
              <div class="vlm-configuration-container" formGroupName="vlmSettings">
                <mat-form-field class="form-field-full vlm-url">
                  <mat-label>{{ 'ui.settings.serverUrl' | translate }}</mat-label>
                  <input
                    required
                    autocomplete="off"
                    type="text"
                    matInput
                    formControlName="serverUrl"
                  />
                  <mat-hint>{{ 'ui.settings.urlServerSide' | translate }}</mat-hint>
                </mat-form-field>
                <mat-form-field class="form-field-full vlm-url">
                  <mat-label>{{ 'ui.settings.clientUrl' | translate }}</mat-label>
                  <input autocomplete="off" type="text" matInput formControlName="clientUrl" />
                  <mat-hint>{{ 'ui.settings.urlClientSide' | translate }}</mat-hint>
                </mat-form-field>
                <mat-form-field class="form-field-full">
                  <mat-label>{{ 'ui.settings.serviceKey' | translate }}</mat-label>
                  <input
                    required
                    autocomplete="off"
                    type="text"
                    matInput
                    formControlName="serviceKey"
                  />
                </mat-form-field>
                <mat-divider></mat-divider>
                <div class="subcard">
                  <mat-card-subtitle>{{ 'ui.settings.native' | translate }}</mat-card-subtitle>
                  <mat-slide-toggle
                    class="switch-container"
                    formControlName="nativeConsoleProxyEnabled"
                  >
                    {{ 'ui.settings.enableProxy' | translate }}
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="subcard">
                  <mat-card-subtitle>{{ 'ui.settings.guacamole' | translate }}</mat-card-subtitle>
                  <mat-radio-group formControlName="guacamoleTunnelType">
                    @for (
                      tunnelType of GuacamoleTunnelType | keyvalue: disableSort;
                      track tunnelType
                    ) {
                      <mat-radio-button id="{{ tunnelType.value }}" [value]="tunnelType.value">
                        {{ tunnelType.value }} {{ 'ui.settings.tunnel' | translate }}
                      </mat-radio-button>
                    }
                  </mat-radio-group>
                  <mat-slide-toggle
                    class="switch-container"
                    formControlName="guacamoleTunnelProxyEnabled"
                  >
                    {{ 'ui.settings.enableProxy' | translate }}
                  </mat-slide-toggle>
                </div>
              </div>
            }
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-title>{{ 'ui.settings.aiFabricConfiguration' | translate }}</mat-card-title>
          <mat-card-content class="aif-settings">
            <mat-slide-toggle class="switch-container" formControlName="aiFabricEnabled"
              >{{ 'ui.settings.enable' | translate }}
            </mat-slide-toggle>
            @if (settingsForm.controls.aiFabricEnabled.value) {
              <div class="aif-configuration-container" formGroupName="aiFabricSettings">
                <mat-form-field class="form-field-full aif-url">
                  <mat-label>{{ 'ui.settings.serverUrl' | translate }}</mat-label>
                  <input
                    required
                    autocomplete="off"
                    type="text"
                    matInput
                    formControlName="serverUrl"
                  />
                  <mat-hint>{{ 'ui.settings.urlServerSide' | translate }}</mat-hint>
                </mat-form-field>
                <mat-form-field class="form-field-full">
                  <mat-label>{{ 'ui.settings.serviceKey' | translate }}</mat-label>
                  <input
                    required
                    autocomplete="off"
                    type="text"
                    matInput
                    formControlName="serviceKey"
                  />
                </mat-form-field>
                <button
                  type="button"
                  mat-raised-button
                  [disabled]="!isAIAvailable"
                  (click)="openAIFabricPromptOverrideDialog()"
                >
                  Configure prompts
                </button>
              </div>
            }
          </mat-card-content>
        </mat-card>
      }
      <mat-card>
        <mat-card-title>{{ 'ui.settings.refreshIntervals' | translate }}</mat-card-title>
        <mat-card-content>
          <mat-form-field
            hintLabel="{{ 'ui.settings.milliseconds' | translate }}"
            class="interval-field"
          >
            <mat-label>{{ 'ui.settings.widgetRefreshInterval' | translate }}</mat-label>
            <input
              id="widgetRefreshInterval"
              type="number"
              min="10000"
              matInput
              formControlName="widgetRefreshInterval"
              required
            />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>{{ 'ui.settings.applicationUrl' | translate }}</mat-card-title>
        <mat-card-content>
          <mat-form-field class="application-url-field">
            <mat-label>{{ 'ui.settings.addAppUrl' | translate }}</mat-label>
            <input id="applicationUrl" type="url" matInput formControlName="applicationUrl" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>{{ 'ui.settings.feSentryDsn' | translate }}</mat-card-title>
        <mat-card-content>
          <mat-form-field class="front-end-sentry-dsn-field">
            <mat-label>{{ 'ui.settings.changeFeSentryDsn' | translate }}</mat-label>
            <input id="frontEndSentryDsn" type="url" matInput formControlName="frontEndSentryDsn" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>{{ 'ui.settings.actions' | translate }}</mat-card-title>
        <mat-card-content>
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="clearAuthorizationCache()"
          >
            {{ 'ui.settings.clearAuthCache' | translate }}
          </button>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>
          {{ 'ui.settings.apiTokens' | translate }}
          <button class="card-title-button" type="button" mat-raised-button (click)="addApiToken()">
            {{ 'ui.settings.generateNew' | translate }}
          </button>
        </mat-card-title>
        <mat-card-content class="gapped">
          <ng-container formArrayName="apiTokens">
            @for (
              apiTokenFormGroup of apiTokenFormArray.controls;
              track apiTokenFormGroup;
              let i = $index
            ) {
              <mat-card class="inner-card" [formGroupName]="i">
                <mat-card-title>
                  <span class="date">
                    {{ 'ui.settings.created' | translate }}
                    {{ apiTokenFormGroup.get('timestamp').value | isaDate: 'medium' }}
                  </span>
                </mat-card-title>
                <mat-card-content class="api-token-card-content">
                  <div class="row-content">
                    <button type="button" (click)="removeApiToken(i)" matSuffix mat-icon-button>
                      <i class="fas fa-times" aria-hidden="true"></i>
                    </button>
                    <mat-form-field class="api-token-name-field">
                      <mat-label>{{ 'ui.settings.name' | translate }}</mat-label>
                      <input type="text" matInput formControlName="name" required />
                      <mat-error>{{ 'ui.settings.nameRequired' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="api-token-field">
                      <mat-label>{{ 'ui.settings.token' | translate }}</mat-label>
                      <input type="text" matInput readonly formControlName="value" />
                    </mat-form-field>
                  </div>
                  <div class="row-content row-content-right">
                    @if (!isSingingKeyDefined(i)) {
                      <div>
                        <button
                          type="button"
                          class="add-signing-key-btn"
                          mat-button
                          (click)="addSigningKey(i)"
                        >
                          <i class="fas fa-plus" aria-hidden="true"></i>
                          {{ 'ui.settings.signingKey' | translate }}
                        </button>
                      </div>
                    }
                    @if (isSingingKeyDefined(i)) {
                      <button
                        type="button"
                        class="remove-signing-key-btn"
                        (click)="removeSigningKey(i)"
                        matSuffix
                        mat-icon-button
                      >
                        <i class="fas fa-times" aria-hidden="true"></i>
                      </button>
                      <mat-form-field
                        class="api-token-field"
                        formGroupName="signingKey"
                        [matTooltip]="'ui.settings.readOnly' | translate"
                      >
                        <mat-label>{{ 'ui.settings.signingKey' | translate }}</mat-label>
                        <input type="text" matInput readonly formControlName="value" />
                      </mat-form-field>
                    }
                  </div>
                </mat-card-content>
              </mat-card>
            } @empty {
              <cybexer-no-data></cybexer-no-data>
            }
          </ng-container>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <div>
          <mat-card-title>{{ 'ui.settings.defaultTheme' | translate }}</mat-card-title>
          <span></span>
          <mat-radio-group label="Default theme" formControlName="isLightTheme">
            <mat-radio-button
              [checked]="!settingsForm.controls['isLightTheme'].value"
              [value]="false"
            >
              {{ 'ui.settings.darkTheme' | translate }}
            </mat-radio-button>
            <mat-radio-button
              [checked]="settingsForm.controls['isLightTheme'].value"
              [value]="true"
            >
              {{ 'ui.settings.lightTheme' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-card>
      <mat-card>
        <mat-card-title>{{ 'ui.settings.language' | translate }}</mat-card-title>
        <mat-card-content>
          <mat-form-field class="language-field">
            <mat-label>{{ 'ui.settings.defaultLanguage' | translate }}</mat-label>
            <mat-select data-testid="defaultLanguage" formControlName="defaultLanguage">
              @for (language of this.settingsForm.get('enabledLanguages')!.value; track language) {
                <mat-option [value]="language">
                  {{ language | convertLanguageCode }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field class="form-field-full">
            <mat-label>
              {{ 'ui.settings.availableLanguages' | translate }}
            </mat-label>
            <mat-select data-testid="enabledLanguages" formControlName="enabledLanguages" multiple>
              @for (language of this.settingsForm.get('languages')!.value; track language) {
                <mat-option [value]="language">
                  {{ language | convertLanguageCode }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>{{ 'ui.settings.customLogo' | translate }}</mat-card-title>
        <mat-card-content>
          @if (customLogoSrc) {
            <div class="image-container">
              <i
                class="fas fa-times delete-icon"
                (click)="openImageRemovalDialog(false); $event.stopPropagation()"
              ></i>
              <img [src]="customLogoSrc | isaSafeUrl" />
            </div>
          }
          @if (!customLogoSrc) {
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="openUploadImageDialog(false)"
            >
              {{ 'ui.settings.uploadCustomLogo' | translate }}
            </button>
          }
        </mat-card-content>
      </mat-card>
      @if (isWhiteLabelEnabled) {
        <mat-card>
          <mat-card-title>{{ 'ui.settings.customLabel' | translate }}</mat-card-title>
          <mat-card-content>
            @if (customLabelSrc) {
              <div class="image-container">
                <i
                  class="fas fa-times delete-icon"
                  (click)="openImageRemovalDialog(true); $event.stopPropagation()"
                ></i>
                <img [src]="customLabelSrc | isaSafeUrl" />
              </div>
            }
            @if (!customLabelSrc) {
              <button
                type="button"
                mat-raised-button
                color="primary"
                (click)="openUploadImageDialog(true)"
              >
                {{ 'ui.settings.uploadCustomLabel' | translate }}
              </button>
            }
          </mat-card-content>
        </mat-card>
      }
      <mat-card>
        <mat-card-title>{{ 'ui.settings.experimentalFeatures' | translate }}</mat-card-title>
        <mat-card-content>
          <mat-slide-toggle
            class="switch-container"
            formControlName="experimentalFeaturesEnabled"
            labelPosition="after"
          >
            {{ 'ui.settings.enabled' | translate }}
          </mat-slide-toggle>
        </mat-card-content>
      </mat-card>
      <div class="sticky-form-buttons">
        <button mat-raised-button type="submit" [disabled]="processing" color="primary">
          {{ 'ui.settings.save' | translate }}
        </button>
      </div>
    </form>
  }
</cybexer-widget-body>
