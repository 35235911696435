import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AttackCampaignData,
  AttackCampaignReport,
  AttackFlagRequest,
  UserAssignment,
  UserAssignmentBatchSaveDTO,
} from '../../models';

@Injectable()
export class AttackCampaignService {
  private baseApiUrl = 'api/widget/attack-campaign';

  constructor(private http: HttpClient) {}

  createUserAssignment(userAssignment: UserAssignment): Observable<any> {
    return this.http.post<HttpResponse<null>>(
      `${this.baseApiUrl}/exercise/${userAssignment.exerciseId}/objective/${userAssignment.objectiveId}/assignment`,
      userAssignment
    );
  }

  createUserAssignments(
    userAssignments: UserAssignment[],
    shouldReassign: boolean
  ): Observable<UserAssignment[]> {
    return this.http.post<UserAssignment[]>(
      `${this.baseApiUrl}/objective/assignment/batch`,
      new UserAssignmentBatchSaveDTO(userAssignments, shouldReassign)
    );
  }

  deleteUserAssignment(
    exerciseId: string,
    objectiveId: string,
    userAssignmentId: string
  ): Observable<boolean> {
    return this.http
      .delete(
        `${this.baseApiUrl}/exercise/${exerciseId}/objective/${objectiveId}/assignment/${userAssignmentId}`
      )
      .pipe(map(() => true));
  }

  createReport(report: AttackCampaignReport): Observable<boolean> {
    return this.http.post(`${this.baseApiUrl}/report`, report).pipe(map(() => true));
  }

  createReports(reports: AttackCampaignReport[]): Observable<boolean> {
    return this.http
      .post<AttackCampaignReport[]>(`${this.baseApiUrl}/report/batch`, reports)
      .pipe(map(() => true));
  }

  getData(exerciseId: string, phaseId: string): Observable<AttackCampaignData[]> {
    return this.http
      .get<AttackCampaignData[]>(`${this.baseApiUrl}/exercise/${exerciseId}/phase/${phaseId}`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new AttackCampaignData(data));
        })
      );
  }

  requestFlag(attackFlagRequest: AttackFlagRequest): Observable<any> {
    return this.http.post<HttpResponse<null>>(
      `${this.baseApiUrl}/attack-flag/request`,
      attackFlagRequest
    );
  }

  getImageUploadUrl(exerciseId: string): string {
    return `${this.baseApiUrl}/exercise/${exerciseId}/images`;
  }
}
