<cybexer-card
  class="exercise"
  [selected]="isSelected"
  [ngClass]="isInGroup ? 'grouped-exercise' : ''"
>
  <mat-card-title>{{ exercise.name }}</mat-card-title>
  <mat-card-subtitle>
    <cybexer-row>
      <cybexer-meta-item>{{ 'ui.enums.' + exercise.type | translate }}</cybexer-meta-item>
      @if (exercise.duration) {
        <cybexer-meta-item [icon]="'fa fa-stopwatch'">
          {{ exercise.duration | isaDuration }}
        </cybexer-meta-item>
      }
      @if (exercise.tasksCount) {
        <cybexer-meta-item [icon]="'fa fa-layer-group'">
          {{ exercise.tasksCount }} {{ 'ui.exercise.tasks' | translate }}
        </cybexer-meta-item>
      }
      @if (exercise.niceIds && exercise.niceIdsCount) {
        <cybexer-meta-item [icon]="'fa fa-check'">
          {{ exercise.niceIdsCount }} {{ 'ui.exercise.niceItems' | translate }}
        </cybexer-meta-item>
      }
      @if (exercise.mitreTags && exercise.mitreTagsCount) {
        <cybexer-meta-item [icon]="'fa fa-check'">
          {{ exercise.mitreTagsCount }} {{ 'ui.exercise.mitreAttackItems' | translate }}
        </cybexer-meta-item>
      }
    </cybexer-row>
  </mat-card-subtitle>
  <mat-card-content>
    <div class="exercise-status">
      <isa-exercise-status-circle
        [status]="exercise.status"
        [isResetInProgress]="exercise.isResetInProgress"
      ></isa-exercise-status-circle>
      @if (!exercise.isResetInProgress) {
        <span>
          @switch (exercise.status) {
            @case ('RUNNING') {
              <span>{{ 'ui.exercise.running' | translate }}</span>
            }
            @case ('STOPPED') {
              <span>{{ 'ui.exercise.stopped' | translate }}</span>
            }
            @case ('NOT_STARTED') {
              <span>{{ 'ui.exercise.notStarted' | translate }}</span>
            }
            @case ('UNPUBLISHED') {
              <span>{{ 'ui.exercise.unpublished' | translate }}</span>
            }
            @case ('SCHEDULED') {
              <span>{{ 'ui.exercise.scheduled' | translate }}</span>
            }
          }
        </span>
      } @else {
        <span>
          {{ 'ui.exercise.resetInProgress' | translate }}
          @if (!showResetSteps) {
            <i
              class="fas fa-info-circle info-icon"
              [matTooltip]="'ui.exercise.resetInProgressTooltip' | translate"
            ></i>
          } @else {
            <span>
              | {{ 'ui.exercise.stepOutOf1' | translate }}
              {{ exerciseResetSteps.get(exercise.id)?.stepsCompleted || 0 }}
              {{ 'ui.exercise.stepOutOf2' | translate }}
              {{ exerciseResetSteps.get(exercise.id)?.totalSteps || MAX_STEPS }}
            </span>
          }
        </span>
      }
    </div>
    <div class="disable-uppercase description">
      {{ exercise.description }}
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button (click)="selectExercise(exercise.id)" mat-button>
      <i class="fas fa-rocket" aria-hidden="true"></i>{{ 'ui.exercise.select' | translate }}
    </button>
    @if (exercise.isTeamSettingsEnabled && isBlueTeam(exercise.id)) {
      <button (click)="openTeamSettings(exercise)" mat-button>
        @if (!exercise.isIndividualAssessment) {
          <i class="fas fa-users" aria-hidden="true"></i
          >{{ 'ui.exercise.teamSettings' | translate }}
        } @else {
          <i class="fas fa-user" aria-hidden="true"></i>{{ 'ui.exercise.userSettings' | translate }}
        }
      </button>
    }
    @if (exercise.type !== ExerciseType.UNPUBLISHED) {
      <button
        *isaRequirePermission="getSystemEventsPermissions(exercise.id); withTarget: true"
        class="start-btn"
        (click)="openExerciseConfirmDialog(exercise, 'STARTEX')"
        mat-button
        [disabled]="exercise.status === 'RUNNING' || exercise.isResetInProgress"
      >
        <i class="fas fa-play" aria-hidden="true"></i> {{ 'ui.start' | translate }}
      </button>
      <button
        *isaRequirePermission="getSystemEventsPermissions(exercise.id); withTarget: true"
        class="stop-btn"
        (click)="openExerciseConfirmDialog(exercise, 'ENDEX')"
        mat-button
        [disabled]="exercise.status !== 'RUNNING'"
      >
        <i class="fas fa-stop" aria-hidden="true"></i> {{ 'ui.stop' | translate }}
      </button>
    }
    <button class="more-info-btn" (click)="openExerciseDialog(exercise)" mat-button>
      <i class="fas fa-info-circle" aria-hidden="true"></i>{{ 'ui.exercise.moreInfo' | translate }}
    </button>
    <ng-container *isaRequirePermission="getSystemEventsPermissions(exercise.id); withTarget: true">
      <button mat-icon-button class="more-actions-button" [matMenuTriggerFor]="moreActionsMenu">
        <i
          [ngClass]="isProcessing ? 'fas fa-spinner fa-spin' : 'fas fa-ellipsis-h'"
          aria-hidden="true"
        ></i>
      </button>
      <mat-menu
        xPosition="before"
        #moreActionsMenu="matMenu"
        [overlapTrigger]="false"
        class="more-actions-menu"
      >
        <button
          mat-menu-item
          data-testid="edit-exercise-btn"
          *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
          [routerLink]="['/app/gamenet/exercise-definition', exercise.id]"
        >
          <i class="fas fa-edit" aria-hidden="true"></i> {{ 'ui.edit' | translate }}
        </button>
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          *isaRequirePermission="[EXERCISE_PERMISSIONS.ADMIN, EXERCISE_PERMISSIONS.WHITE_TEAM]"
          [routerLink]="['/app/gamenet/team-assignment', exercise.id]"
          data-testid="pre-assign-teams-btn"
        >
          <i class="fas fa-users" aria-hidden="true"></i>
          {{ 'ui.exercise.preAssignTeams' | translate }}
        </button>
        @if (exercise.type === ExerciseType.CTF) {
          <button
            mat-menu-item
            *isaRequirePermission="[EXERCISE_PERMISSIONS.ADMIN, EXERCISE_PERMISSIONS.WHITE_TEAM]"
            [routerLink]="['/app/gamenet/waiting-room/statistics', exercise.id]"
            data-testid="queue-stats-btn"
          >
            <i class="fas fa-chart-line" aria-hidden="true"></i>
            {{ 'ui.exercise.monitoring' | translate }}
          </button>
        }
        <button
          mat-menu-item
          *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
          (click)="openExerciseIntegrationsDialog(exercise)"
        >
          <i class="fas fa-plug" aria-hidden="true"></i>
          {{ 'ui.exercise.integrations' | translate }}
        </button>
        <button
          mat-menu-item
          *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
          (click)="openExerciseImageDialog(exercise)"
          data-testid="edit-exercise-image-btn"
        >
          <i class="fas fa-image" aria-hidden="true"></i>
          {{ (exercise.imageId ? 'ui.exercise.editImage' : 'ui.exercise.addImage') | translate }}
        </button>
        @if (exercise.type === ExerciseType.CAMPAIGN) {
          <button
            mat-menu-item
            (click)="reloadScripts(exercise.id)"
            [disabled]="isProcessing || exercise.status === 'UNPUBLISHED'"
            data-testid="reload-scripts-btn"
          >
            <i class="fas fa-sync" aria-hidden="true"></i>
            {{ 'ui.exercise.reloadScripts' | translate }}
          </button>
        }
        @if (isAiAvailable && exercise.status === 'STOPPED') {
          <button
            mat-menu-item
            *isaRequirePermission="getSystemEventsPermissions(exercise.id); withTarget: true"
            class="aar-export-btn"
            (click)="openAarExportDialog()"
          >
            <i class="fas fa-file-word" aria-hidden="true"></i>
            {{ 'ui.aar.composer' | translate }}
          </button>

          <div
            [matTooltip]="
              'ui.reports.activeOverride'
                | translate: { promptName: effectivePromptOverride?.name || '?' }
            "
            matTooltipPosition="left"
            [matTooltipDisabled]="!effectivePromptOverride?.id"
          >
            <button
              mat-menu-item
              (click)="openAIFabricPromptOverrideDialog()"
              *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
            >
              <i class="fas fa-robot" aria-hidden="true"></i>
              @if (effectivePromptOverride?.id) {
                {{
                  'ui.aar.promptOverridesButton'
                    | translate: { promptName: effectivePromptOverride?.name }
                }}
              } @else {
                {{ 'ui.reports.promptOverridesButtonDefault' | translate }}
              }
            </button>
          </div>
        }
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
          (click)="openExerciseResetDialog(exercise)"
          [disabled]="exercise.status !== 'STOPPED' || exercise.isResetInProgress"
          data-testid="reset-exercise-btn"
        >
          <i class="fas fa-undo-alt" aria-hidden="true"></i> {{ 'ui.reset' | translate }}
        </button>
        <div
          [matTooltip]="'ui.exercise.resetBeforeDeleting' | translate"
          matTooltipPosition="left"
          [matTooltipDisabled]="exercise.status === ExerciseStatus.NOT_STARTED"
        >
          <button
            mat-menu-item
            *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
            (click)="openExerciseDeleteDialog(exercise)"
            [disabled]="exercise.status !== ExerciseStatus.NOT_STARTED"
            data-testid="delete-exercise-btn"
          >
            <i class="fas fa-trash-alt" aria-hidden="true"></i>
            {{ 'ui.delete' | translate }}
          </button>
        </div>
      </mat-menu>
    </ng-container>
  </mat-card-actions>
</cybexer-card>
