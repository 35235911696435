import { ObjectiveStatus } from '../../shared';
import { NameFilter } from '../../shared/name-filter';
import { BaseModel } from '../shared/base.model';
import { MitreTags } from './ctf/ctf-mission.model';

export class UserAssignment extends BaseModel {
  id?: string;
  objectiveContainerId: string;
  objectiveId: string;
  teamId: string;
  exerciseId: string;
  timestamp?: Date;
  unassigned?: Date;

  constructor(data?) {
    super(data);
  }
}

export class AttackCampaignReport extends BaseModel {
  exerciseId: string;
  teamId: string;
  objectiveId: string;
  objectiveContainerId: string;
  status: string;
  evidence: string;
  flagFileFullPath?: string;
  flagFileContent?: string;

  constructor(data?) {
    super(data);
  }
}

export class AttackFlagRequest extends BaseModel {
  exerciseId: string;
  objectiveId: string;
  isAdminPermissions: boolean;

  constructor(data?) {
    super(data);
  }
}

export class AttackCampaignData extends BaseModel {
  campaignPhaseId: string;
  campaignPhaseName: string;
  objectives: AttackCampaignDataObjective[] = [];

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('objectives') && data.objectives != null) {
      this.objectives = data.objectives.map(
        (objective) => new AttackCampaignDataObjective(objective)
      );
    }
  }
}

export class AttackCampaignDataObjective extends BaseModel implements NameFilter {
  type: string;
  name: string;
  category: string;
  teams: AttackCampaignDataObjectiveTeam[] = [];
  mitreTags: MitreTags;
  reportTemplate?: string;
  solution?: string;
  technicalDetails?: string;
  // this property is used for tracking objectives in for loop in the attack-campaign.component.html template
  trackBy?: string;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('teams') && data.teams != null) {
      this.teams = data.teams.map((team) => new AttackCampaignDataObjectiveTeam(team));
    }
  }
}

export class AttackCampaignDataObjectiveTeam extends BaseModel {
  teamId: string;
  teamName: string;
  objectiveContainerId: string;
  objectiveStatus: ObjectiveStatus;
  targetCheckAvailabilityStatus: boolean;
  objectiveUserName: string;
  objectiveId: string;
  userAssignmentId: string;
  isAttackAttempted: boolean;
  reportCount: number;

  constructor(data?) {
    super(data);
  }
}

export class UserAssignmentBatchSaveDTO {
  constructor(
    public assignments: UserAssignment[],
    public shouldReassign: boolean
  ) {}
}
