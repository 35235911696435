import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import {
  AttackCampaignDataObjective,
  AttackCampaignDataObjectiveTeam,
  UserAssignment,
} from '../../../../models';
import { AttackCampaignService } from '../../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'isa-multi-assign-dialog',
  templateUrl: './multi-assign-dialog.component.html',
  styleUrls: ['./multi-assign-dialog.component.scss'],
})
export class MultiAssignDialogComponent implements OnInit {
  filteredTeams: AttackCampaignDataObjectiveTeam[];
  shouldReassign: boolean = false;
  isError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MultiAssignInput,
    private dialogRef: MatDialogRef<MultiAssignDialogComponent, MultiAssignResult>,
    private attackCampaignService: AttackCampaignService,
    private notificationsService: NotificationsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.filteredTeams = this.data.objective.teams.filter(
      (team) => team.objectiveUserName !== this.data.currentUsername
    );
  }

  assign() {
    const assignments = this.filteredTeams
      .filter((team) => team.objectiveUserName == null || this.shouldReassign)
      .map((team) => {
        return {
          objectiveContainerId: team.objectiveContainerId,
          objectiveId: team.objectiveId,
          teamId: team.teamId,
          exerciseId: this.data.exerciseId,
        } as UserAssignment;
      });

    if (assignments.length === 0) {
      this.isError = true;
      return;
    }

    this.attackCampaignService
      .createUserAssignments(assignments, this.shouldReassign)
      .subscribe((savedAssignments) => {
        this.notificationsService.success(
          this.translate.instant('ui.attackCampaign.objectivesAssigned')
        );
        this.dialogRef.close({ assignments: savedAssignments });
      });
  }

  toggleReassignment() {
    this.shouldReassign = !this.shouldReassign;
    this.isError = false;
  }
}

export interface MultiAssignInput {
  objective: AttackCampaignDataObjective;
  currentUsername: string;
  exerciseId: string;
}

export interface MultiAssignResult {
  assignments: UserAssignment[];
}
